import { isMainnet } from '@/composables/useNetwork';
import { Pools } from '@/types/pools';
import { configService } from '@/services/config/config.service';

export const MIN_FIAT_VALUE_POOL_MIGRATION = isMainnet.value ? 100_000 : 1; // 100K USD or $1 for other networks

// Do not display APR values greater than this amount; they are likely to be nonsensical
// These can arise from pools with extremely low balances (e.g., completed LBPs)
export const APR_THRESHOLD = 1_000_000;
export const VOLUME_THRESHOLD = 1_000_000_000;
export const EVENT_SALE_TYPE = {
  SIMPLE_SUBSCRIPTION: 4,
  DUTCH_AUCTION: 0,
  DIRECT_ALLOCATION: 1,
  NORMAL_SALE_SOT: 2,
};

/**
 * For proportional exits from ComposableStable pools the ExactBPTInForTokensOut
 * exit type was removed. Therefore we have to use BPTInForExactTokensOut which
 * makes proportional exits using a user's total BPT balance impossible. In
 * order to 'fix' this we need to subtract a little bit from the bptIn value
 * when calculating the ExactTokensOut. The variable below is that "little bit".
 */
export const SHALLOW_COMPOSABLE_STABLE_BUFFER = 1e9; // EVM scale, so this is 1 Gwei

export const POOLS: Pools = configService.network.pools;

export enum PoolAction {
  Add = 'add',
  Remove = 'remove',
  Stake = 'stake',
  Unstake = 'unstake',
  Vote = 'vote',
  MigrateGauge = 'migrateGauge',
  Poke = 'poke',
  ViewDetails = 'viewDetails',
  ViewDocument = 'viewDocument',
  Withdraw = 'withdraw',
}

/**
 * List of poolIds for testing purpose on PROD
 */
export const DEMO_POOL_IDS = [
  2, // Pool: Karmen Beta
  3, // Pool: Karmen Mezz
];

/**
 * List of poolIds for testing purpose on PROD
 */
export const DEMO_VAULT_IDS = [3, 30, 29]; // 29: Ubet vault
