import { networkId } from '@/composables/useNetwork';
import { Config } from '@/lib/config/types';
import configs, { Network } from '@/lib/config';
import template from '@/lib/utils/template';

interface Env {
  APP_ENV: string;
  APP_DOMAIN: string;
  APP_HOST: string;
  API_URL: string;
  API_BACKEND: string;
  API_BACKEND_V2: string;
  API_BACKEND_V4: string;
  IPFS_NODE: string;
  BLOCKNATIVE_DAPP_ID: string;
  ALCHEMY_KEY: string;
  GRAPH_KEY: string;
  INFURA_PROJECT_ID: string;
  ENABLE_STABLE_POOLS: boolean;
  WALLET_SCREENING: boolean;
  ACC_DOMAIN: string;
  VERIFY_CONTRACT: string;
  PERSONA_TEMPLATE_ID: string;
  PUBLIC_RP_ID: string;
  PUBLIC_RP_NAME: string;
  PASSKEY_SCOPE_ID: string;
  BICONOMY_BUNDLER_ID: string;
  BICONOMY_PAYMASTER_API_KEY: string;
}
export default class ConfigService {
  public get env(): Env {
    return {
      APP_ENV: import.meta.env.VITE_ENV || 'development',
      APP_DOMAIN: import.meta.env.VITE_DOMAIN || 'app.balancer.fi',
      APP_HOST: import.meta.env.VITE_HOST || 'balancer.fi',
      API_URL:
        import.meta.env.VITE_API_URL || 'https://api-v3.balancer.fi/graphql',
      API_BACKEND:
        import.meta.env.VITE_API_BACKEND ||
        'https://test-api.untangled.finance',
      API_BACKEND_V2:
        import.meta.env.VITE_API_BACKEND_V2 ||
        'https://test-api2.untangled.finance',
      API_BACKEND_V4:
        import.meta.env.VITE_API_BACKEND_V4 ||
        'https://api-dev.untangled.finance',
      IPFS_NODE: import.meta.env.VITE_IPFS_NODE || 'cloudflare-ipfs.com',
      BLOCKNATIVE_DAPP_ID:
        import.meta.env.VITE_BLOCKNATIVE_DAPP_ID || 'MISSING_KEY',
      ALCHEMY_KEY:
        import.meta.env.VITE_ALCHEMY_KEY ||
        this.getNetworkConfig(networkId.value).keys.alchemy ||
        'MISSING_KEY',
      GRAPH_KEY:
        import.meta.env.VITE_ENV === 'development'
          ? import.meta.env.VITE_GRAPH_KEY_DEV || 'MISSING_KEY'
          : import.meta.env.VITE_GRAPH_KEY ||
            this.getNetworkConfig(networkId.value).keys.graph ||
            'MISSING_KEY',
      INFURA_PROJECT_ID:
        import.meta.env.VITE_INFURA_PROJECT_ID ||
        this.getNetworkConfig(networkId.value).keys.infura ||
        'MISSING_KEY',
      ENABLE_STABLE_POOLS: import.meta.env.VITE_ENABLE_STABLE_POOLS === 'true',
      WALLET_SCREENING: import.meta.env.VITE_WALLET_SCREENING === 'true',
      ACC_DOMAIN:
        import.meta.env.VITE_ACC_DOMAIN ||
        'https://test-account.untangled.finance',
      VERIFY_CONTRACT:
        import.meta.env.VITE_VERIFY_CONTRACT ||
        '0xC839b27ca5b728b44cBB5E8a58930c59F8D24F91',
      PERSONA_TEMPLATE_ID:
        import.meta.env.VITE_PERSONA_TEMPLATE_ID ||
        'itmpl_i1bE8U19ZyepQbUMRVtAiPCq',
      PUBLIC_RP_ID:
        import.meta.env.VITE_PUBLIC_RP_ID || 'app-test.untangled.finance',
      PUBLIC_RP_NAME: import.meta.env.VITE_PUBLIC_RP_NAME || 'Untangled Test',
      PASSKEY_SCOPE_ID:
        import.meta.env.VITE_PASSKEY_SCOPE_ID ||
        '08089d1e-32bd-40c6-bceb-4cabae85413a',
      BICONOMY_BUNDLER_ID:
        import.meta.env.VITE_BICONOMY_BUNDLER_ID ||
        'nJPK7B3ru.dd7f7861-190d-41bd-af80-6877f74b8f44',
      BICONOMY_PAYMASTER_API_KEY:
        import.meta.env.VITE_BICONOMY_PAYMASTER_API_KEY ||
        '8j-vHOJug.89e673e2-cead-4f59-aba8-644fa49e1a87',
    };
  }

  public get isDevEnv(): boolean {
    return this.env.APP_ENV === 'development';
  }

  public get network(): Config {
    return configs[networkId.value];
  }

  public getNetworkConfig(key: Network): Config {
    if (!Object.keys(configs).includes(key?.toString()))
      throw new Error(`No config for network key: ${key}`);
    return configs[key];
  }

  public getNetworkRpc(network: Network): string {
    const networkConfig = this.getNetworkConfig(network);

    return template(
      import.meta.env[`VITE_RPC_URL_${network}`] || networkConfig.rpc,
      {
        INFURA_KEY: networkConfig.keys.infura,
        ALCHEMY_KEY: networkConfig.keys.alchemy,
      }
    );
  }

  public get rpc(): string {
    return template(
      import.meta.env[`VITE_RPC_URL_${networkId.value}`] ||
        this.getNetworkConfig(networkId.value).rpc,
      {
        INFURA_KEY: this.env.INFURA_PROJECT_ID,
        ALCHEMY_KEY: this.env.ALCHEMY_KEY,
      }
    );
  }

  public get subgraphUrls(): string[] | void {
    return this.network.subgraphs.main?.map(url => {
      if (url.includes('GRAPH_KEY')) {
        return template(url, {
          GRAPH_KEY: this.env.GRAPH_KEY,
        });
      }
      return url;
    });
  }

  public get ws(): string {
    return template(this.network.ws, {
      INFURA_KEY: this.env.INFURA_PROJECT_ID,
      ALCHEMY_KEY: this.env.ALCHEMY_KEY,
    });
  }
}

export const configService = new ConfigService();
